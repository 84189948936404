import React from "react";
import './certificate.css'
import apeda from '../assects/Apeda logo.png'
import javik from '../assects/Jaivik Bharat logo.png'
import indiaorg from '../assects/India organic logo.png'
import Usda from '../assects/USDA logo.png'
import fssai from '../assects/Fssai logo.png'
import mission from '../assects/4.jpg'
// import cetrifications from './Certifications.png'

const Certificate = () => {
    return (
        <div>
            <div className="cr">
                <div className="cr-heading">
                    <h1>Quality Standards</h1>
                    <div className="cr-para"><p>
                        Establish strict quality standards and certifications to ensure that our organic products meet or
                        exceed international market expectations. Certifications such as India Organic (NPOP), USDA Organic
                        (NOP), or equivalent standards in other regions will be crucial for building trust and gaining market access.</p></div>
                </div>
                <div className="certi-img">
                    <div className="certi-img-box">
                        <img src={apeda} alt="">
                        {/* <a href="Fssai.js"></a> */}
                        </img>
                    </div>
                    <div className="certi-img-box">
                        <img src={javik} alt=""></img>
                    </div>
                    <div className="certi-img-box">
                        <img src={indiaorg} alt=""></img>
                    </div>
                    <div className="certi-img-box">
                        <img src={Usda} alt=""></img>
                    </div>
                    <div className="certi-img-box">
                        <img src={fssai} alt=""></img>
                    </div>
                </div>
                {/* <div className="certificate"><img src={cetrifications}></img></div> */}
            </div>
            <div className="missioon">
                <div className="our-mission">
                    <img  src={mission} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Certificate;