import React from "react";
import styles from  './Header.module.css'
// import animatedlogo from './logodesign2.png'
// import filogo from '../assects/fina logo.jpeg'
// import index from '../assects/indexlogo.jpeg'
import logo from '../assects/Logo_forest.PNG'

const Header = () => {
    return (
        <div className={styles.homepg}>
            <div className={styles.head}>
                <div className={styles.headtop}>
                </div>
                <h1>Forest
                    Wellness
                </h1>
                <br />
                <div className={styles.slogan}>
                    <h2>Harnessing The Power Of Nature</h2>
                </div>
            </div>
            <div className={styles.animatelogo}>
                <img src={logo} alt=" animated logo" />
            </div>
        </div>
    );
};

export default Header;

// import React from "react";
// import './Header.css';
// import logo from '../assects/Logo_forest.PNG';

// const Header = () => {
//     return (
//         <div className="container-fluid homepg">
//             <div className="row align-items-center justify-content-center text-center">
//             <div className="col-12 animate-logo">
//                     <img src={logo} alt="animated logo" className="img-fluid" />
//                 </div>
//                 <div className="col-12 head">
//                     <h1>Forest Wellness</h1>
//                     <br />
//                     <div className="slogan">
//                         <h2>Harnessing The Power Of Nature</h2>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default Header;